import moment from 'moment';
import Cookies from 'js-cookie';
import { size, round, find, pickBy } from 'lodash';
import 'moment-timezone';
import { translateLanguage as t, getPriceByCurrency } from '@/core/utils';
import { listingTypes, propertyTypes } from '@/settings';
import { getSelectedCurrency } from '@/core/utils/getPriceByCurrency';

export const getActualDate = (returnMoment, timestamp) => {
  let date = timestamp;
  if (!date) date = new Date();

  let newDate = null;
  const timeZone = sessionStorage.getItem('timezone');
  if (timeZone && timeZone !== 'undefined') {
    newDate = moment.tz(date, timeZone);
  } else newDate = moment(date, 'YYYY-MM-DD HH:mm:ss');

  if (returnMoment) return newDate;
  return newDate.toDate();
};

export const getDateFormat = () => sessionStorage.getItem('dateFormat') || 'DD.MM.YYYY';

export const getHourFormat = () => sessionStorage.getItem('timeFormat');

export const getFormattedDate = (date, time = false) => {
  if (!date) return '';
  const actualDate = getActualDate(true, date);
  const hourFormat = getHourFormat() && getDateFormat() === '24_hours' ? 'HH:mm' : 'hh:mm';
  const dateFormat = time ? `${getDateFormat()} ${hourFormat}` : getDateFormat();

  return actualDate ? actualDate.format(dateFormat) : '';
};

export const convertToDecimal = (amount, type) => {
  if (type === 'round') {
    const invoiceSettings = Cookies.get('invoiceSettings');

    if (invoiceSettings && invoiceSettings.numberOfDecimalInInvoice === 0) return round(amount);
    return parseFloat(parseFloat(amount).toFixed(2));
  }
  return parseFloat(parseFloat(amount).toFixed(2));
};

export const getTimeFormat = (time) => {
  const timeFormat = sessionStorage.getItem('timeFormat');

  if (timeFormat === '24_hours') return moment(time).format('HH:mm');
  return moment(time).format('hh:mm');
};

export const getCalendarDate = (timestamp, returnDate) => {
  if (!timestamp) return '';
  const date = moment(timestamp);
  const today = moment();
  const difference = today.diff(date);

  // if (returnDate && difference > 0) {
  //   if (difference > 1) return getFormattedDate(timestamp, true);
  //   return getTimeFormat(timestamp);
  // }
  return moment(timestamp).fromNow();
};

export const getFirstLetters = (value) =>
  value
    ? value
        .split(' ')
        .map((name) => name[0])
        .join('')
        .toUpperCase()
    : '';

export const getFormattedLocation = (location) => {
  if (!size(location)) return '';
  const { name, city, country, lat, lng } = location;
  return name.includes(',') ? name : `${name}, ${city}, ${country}`;
};

export const getListingPriceText = (price = 0, listingCurrency) => {
  if (listingCurrency) {
    let priceValue = price;
    let userSettingsCurrency = getSelectedCurrency();

    if (userSettingsCurrency) {
      priceValue = getPriceByCurrency(price, true, listingCurrency, userSettingsCurrency);
    } else userSettingsCurrency = listingCurrency;

    return (
      <>
        {userSettingsCurrency} {priceValue}
      </>
    );
  }
  return 0;
};

export const getPartnerId = () => {
  const partnerId = Cookies.get('partnerId');
  if (partnerId) {
    return partnerId;
  }
  return '';
};

export const getPropertyAddress = (location = {}) => {
  if (!location) return '';

  const name = location && location.name ? location.name : '';
  const zipCode = location.postalCode ? `, ${location.postalCode}` : '';
  const city = location.city ? `, ${location.city}` : '';
  const country = location.country ? `, ${location.country}` : '';

  return name + zipCode + city + country;
};

export const getFileIcon = (content, viewType) => {
  const result = {};

  if (content) {
    const fileFormat = content?.split('.').pop();

    if (viewType === 'me') {
      if (fileFormat === 'txt') result.icon = '/assets/images/file-icon/text-white.svg';
      else if (fileFormat === 'rtf') result.icon = '/assets/images/file-icon/rtf-file-white.svg';
      else if (fileFormat === 'xls') result.icon = '/assets/images/file-icon/xls-file-white.svg';
      else if (fileFormat === 'xlsx') result.icon = '/assets/images/file-icon/xlsx-file-white.svg';
      else if (fileFormat === 'xml') result.icon = '/assets/images/file-icon/xml-file.svg';
      else if (fileFormat === 'ppt') result.icon = '/assets/images/file-icon/ppt-file-white.svg';
      else if (fileFormat === 'pptx') result.icon = '/assets/images/file-icon/pptx-file-white.svg';
      else if (fileFormat === 'doc') result.icon = '/assets/images/file-icon/doc-file-white.svg';
      else if (fileFormat === 'docx') result.icon = '/assets/images/file-icon/docx-file-white.svg';
      else if (fileFormat === 'pdf') result.icon = '/assets/images/file-icon/pdf-file-white.svg';
      else if (fileFormat === 'pages') result.icon = '/assets/images/file-icon/file-white.svg';
      else if (fileFormat === 'numbers') result.icon = '/assets/images/file-icon/file-white.svg';
      else if (fileFormat === 'psd') result.icon = '/assets/images/file-icon/psd-file-white.svg';
      else if (fileFormat === 'ai') result.icon = '/assets/images/file-icon/ai-file-white.svg';
      else result.icon = '/assets/images/file-icon/file-white.svg';
    } else if (viewType !== 'me') {
      if (fileFormat === 'txt') result.icon = '/assets/images/file-icon/text.svg';
      else if (fileFormat === 'rtf') result.icon = '/assets/images/file-icon/rtf-file.svg';
      else if (fileFormat === 'xls') result.icon = '/assets/images/file-icon/xls-file.svg';
      else if (fileFormat === 'xlsx') result.icon = '/assets/images/file-icon/xlsx-file.svg';
      else if (fileFormat === 'ppt') result.icon = '/assets/images/file-icon/ppt-file.svg';
      else if (fileFormat === 'pptx') result.icon = '/assets/images/file-icon/pptx-file.svg';
      else if (fileFormat === 'doc') result.icon = '/assets/images/file-icon/doc-file.svg';
      else if (fileFormat === 'docx') result.icon = '/assets/images/file-icon/docx-file.svg';
      else if (fileFormat === 'pdf') result.icon = '/assets/images/file-icon/pdf-file.svg';
      else if (fileFormat === 'pages') result.icon = '/assets/images/file-icon/file.svg';
      else if (fileFormat === 'numbers') result.icon = '/assets/images/file-icon/file.svg';
      else if (fileFormat === 'psd') result.icon = '/assets/images/file-icon/psd-file.svg';
      else if (fileFormat === 'ai') result.icon = '/assets/images/file-icon/ai-file.svg';
    }
  }
  return result;
};

export const getListingTypeInfo = (listingTypeId) =>
  find(listingTypes, (typeInfo) => {
    if (typeInfo && typeInfo.id && listingTypeId === typeInfo.id) return typeInfo;
    return null;
  });

export const getPropertyTypeInfo = (propertyTypeId) =>
  find(propertyTypes, (typeInfo) => {
    if (typeInfo && typeInfo.id && propertyTypeId === typeInfo.id) return typeInfo;
    return null;
  });

export const validatedTextInputField = (value) => {
  if (value?.indexOf(' ') !== 0) {
    return true;
  }
  return false;
};

/**
 * @deprecated use getPropertyAddress in ./common instead
 */
export const getAddress = (location) => {
  const locationObj = {
    name: location?.name,
    postalCode: location?.postalCode,
    city: location?.city,
    country: location?.country
  };
  const address = Object.values(pickBy(locationObj))?.join(', ');
  return address;
};

export const isImageFile = (content) => {
  if (!content) return false;
  const extension = content.split('.').pop();
  const listOfImageType = ['jpg', 'png', 'jpeg', 'gif', 'svg'];
  if (listOfImageType?.includes(extension)) return true;
  return false;
};
